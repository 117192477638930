@import "../../../styles/base/abstracts";

.hbs-statistic {
  @include stat-size(small);
}

.hbs-statistic__unit {
  letter-spacing: -0.01em;
  margin-left: $spacing-xxxs;
}

.hbs-statistic__caption-text {
  @include baseline-text;
  max-width: 18em;
}
